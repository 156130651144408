import React, { memo } from 'react'
import { ToastContainer } from 'react-toastify'

import ToastStyled from './styledComponent'
import colors from '../../colors'
import 'react-toastify/dist/ReactToastify.css'

import CloseIcon from '../../asset/images/modal-overlay/close.svg'

const ToastComponent = () => {
  const renderCloseIcon = () => {
    return (
      <div style={{ width: 15, height: 15 }}>
        <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={CloseIcon} alt="" />
      </div>
    )
  }

  return (
    <ToastStyled theme={colors}>
      <ToastContainer position="top-right" hideProgressBar autoClose={5000} closeButton={renderCloseIcon} />
    </ToastStyled>
  )
}

export default memo(ToastComponent)
