import styled from 'styled-components'
import SuccessIcon from '../../asset/images/check-toast.svg'

export default styled.div`
  .Toastify__toast-container {
    width: 350px;
    .Toastify__toast--success {
      background-color: #fff;
      height: 72px;
      &::before {
        display: flex;
        align-items: center;
        content: url(${SuccessIcon});
        width: 56px;
        height: 56px;
      }
      .Toastify__toast-body {
        color: ${(props) => props.theme.darkBackground};
        margin-left: 8px;
        padding: 0;
        .toast-text {
          p {
            padding: 0;
            margin: 0;
          }
          height: 100%;
          .title {
            font-family: 'Prompt-Bold', sans-serif;
            font-size: 14px;
          }
          .message {
            font-family: 'Prompt', sans-serif;
            font-size: 12px;
          }
        }
      }
    }
  }
`
